export default class fieldsAnalyseValidationModel {
  constructor() {
    this.list = [
      {
        disabled: true,
        key: 'numero_dossier',
        label: 'Numéro de dossier',
        thClass: 'num-lot-th',
        tdClass: 'num-lot-td'
      },
      { disabled: false, key: 'version', label: 'Version' },
      { disabled: true, key: 'depot', label: 'Dépôt' },
      { disabled: true, key: 'statut', label: 'Statut' },
      { disabled: true, key: 'organisme', label: 'Délégataire' },
      { disabled: true, key: 'installeur', label: 'Installateur RGE' },
      { disabled: false, key: 'nom1', label: 'Nom 1' },
      { disabled: false, key: 'prenom1', label: 'Prénom 1' },
      { disabled: false, key: 'adresse', label: 'Adresse' },
      { disabled: false, key: 'code_postal', label: 'Code postal' },
      { disabled: false, key: 'departement', label: 'Département' },
      { disabled: false, key: 'etat_de_statut', label: 'Etat du statut' },
      { disabled: false, key: 'etape', label: 'Etape' },
      { disabled: false, key: 'categorie', label: 'Catégorie' },
      { disabled: false, key: 'super_regie', label: 'Régie/Supper régie' },
      { disabled: false, key: 'regie', label: 'Régie/Raison Sociale' },
      {
        disabled: false,
        key: 'agent_commercial_terrain',
        label: 'Agent commercial terrain'
      },
      { disabled: false, key: 'agent_commercial', label: 'Agent Confirmateur' },
      {
        disabled: false,
        key: 'agent_administratif',
        label: 'Agent administratif'
      },
      { disabled: false, key: 'agent_technique', label: 'Agent technique' },
      {
        disabled: false,
        key: 'responsable_qualite',
        label: 'Responsable Qualité'
      },
      { disabled: false, key: 'pose_date', label: 'Pose date' },
      { disabled: false, key: 'pose_semaine', label: 'Pose semaine' },
      { disabled: false, key: 'poseur', label: 'Poseur ISO' },
      { disabled: false, key: 'poseur_pac', label: 'Poseur PAC' },
      { disabled: false, key: 'coposeurs', label: 'Coposeurs' },
      {
        disabled: false,
        key: 'conducteur_de_travaux',
        label: 'Conducteur de travaux'
      },
      { disabled: false, key: 'montant_final', label: 'Montant Min' },
      { disabled: false, key: 'montant_total_ht', label: 'Montant Total HT' },
      { disabled: false, key: 'montant_net_ht', label: 'Montant Net HT' },
      { disabled: false, key: 'montant_TVA', label: 'Montant TVA' },
      { disabled: false, key: 'montant_prime_cee', label: 'Montant Prime CEE' },
      {
        disabled: false,
        key: 'montant_mpr',
        label: 'Montant Prime MaPrimeRenov'
      },
      { disabled: false, key: 'montant_total_ttc', label: 'Montant Total TTC' },
      { disabled: false, key: 'reste_a_charge', label: 'reste à charge' },
      { disabled: false, key: 'ballon', label: 'Ballon' },
      { disabled: false, key: 'RO', label: 'R/O' },
      { disabled: false, key: 'RR', label: 'R/R' },
      { disabled: false, key: 'surface_101_souflee', label: 'Soufflée' },
      { disabled: false, key: 'surface_101_deroule', label: 'Déroulé' },
      { disabled: false, key: 'surface_103_plafond', label: 'Plafond' },

      { disabled: false, key: 'surface_101_rampants', label: 'Rampant +' },
      { disabled: false, key: 'surface_102_murs_iti', label: 'Mur +' },
      { disabled: false, key: 'surface_102_murs_ite', label: 'ITE' },
      { disabled: false, key: 'VMC', label: 'VMC' },
      { disabled: false, key: 'kwh_cumac', label: 'Kwh cumac' },
      { disabled: false, key: 'precarite', label: 'Précarité' },
      { disabled: false, key: 'couleur', label: 'Couleur précarité' },
      { disabled: false, key: 'type_de_chauffage', label: 'Type de chauffage' },
      {
        disabled: false,
        key: 'personnes_au_foyer',
        label: 'Personnes au foyer'
      },
      { disabled: false, key: 'etat_du_devis', label: 'Etat du devis' },
      { disabled: false, key: 'devis_signe_par', label: 'Devis signé par' },
      { disabled: false, key: 'numero_de_facture', label: 'Numéro de facture' },
      { disabled: false, key: 'numero_de_lot', label: 'Numéro de lot' },
      { disabled: false, key: 'numero_de_depot', label: 'Numéro de dépôt' },
      { disabled: false, key: 'date_dengagement', label: "Date d'engagement" },
      { disabled: false, key: 'date_facture', label: 'Date facture' },
      {
        disabled: false,
        key: 'date_depot_dossier',
        label: 'Date dépôt dossier'
      },
      { disabled: false, key: 'depot_stockage', label: 'Zone dépôt stockage' },
      {
        disabled: false,
        key: 'revenu_fiscal_ref',
        label: 'Revenu fiscal Réf.'
      },
      { disabled: false, key: 'email', label: 'Email' },
      { disabled: false, key: 'source', label: 'Source' },
      {
        disabled: false,
        key: 'date_creation_de_la_fiche',
        label: 'Date création de la fiche'
      },
      { disabled: false, key: 'type_lead', label: 'Type Lead' },
      { disabled: false, key: 'commentaire', label: 'Commentaire' },
      { disabled: false, key: 'zone_climatique', label: 'Zone climatique' },
      { disabled: false, key: 'previsite_date', label: 'Prévisite date' },
      { disabled: false, key: 'dossier_mpr', label: 'Dossier MPR' },
      { disabled: false, key: 'type_isolation', label: 'Type Isolation' },
      { disabled: false, key: 'numero_avoir', label: "Numéro d'avoir" },
      { disabled: false, key: 'date_avoir', label: "Date de l'avoir" },
      {
        disabled: false,
        key: 'montant_ttc_avoir',
        label: "Montant TTC de l'avoir"
      },
      {
        disabled: false,
        key: 'montant_tva_avoir',
        label: "Montant TVA de l'avoir"
      },
      {
        disabled: false,
        key: 'cofrac_bureau_de_control',
        label: 'Cforac/Bureau de contrôle'
      },
      { disabled: false, key: 'dossier_ID', label: 'Dossier ID' },
      { disabled: false, key: 'BE_TYPE', label: "BAO/Bureau d'études" },
      { disabled: false, key: 'statue_date', label: 'Statut Date' },
      {
        disabled: false,
        key: 'coef_prime_installateur',
        label: 'Coef prime installateur'
      }
    ];
  }

  static create() {
    return new fieldsAnalyseValidationModel();
  }
}
